@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #110c24;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #1967fc;
  border-radius: 10px;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
